.containerSidebar {
    display: grid;
    gap: var(--space-48);

    @media (--screen-gt-lg) {
        grid-template-columns: 15rem minmax(0, 1fr);

        &.lg {
            grid-template-columns: 17.5rem minmax(0, 1fr);
        }
    }
}

.main {
    @media (--screen-gt-lg) {
        grid-column: 2;
    }
}

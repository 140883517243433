.container {
    position: relative;

    .checkmark {
        position: absolute;
        bottom: -2px;
        right: -2px;
    }

    .count {
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        inset: 0;
        background: var(--display-onlight-secondary);
        border-radius: inherit;

        p {
            color: #fff;
        }
    }
}

.avatarStack {
    display: flex;

    > *:not(:first-child) {
        margin-left: calc(-1 * var(--space-8));
    }

    > * {
        border-radius: 50%;
        box-shadow: 0 0 0 1px var(--composition-background-white);
    }
}

.schedulerContextMenu {
    display: flex;
    flex-direction: column;
    gap: var(--space-16);
    padding: var(--space-12);

    hr {
        margin: 0 0 var(--space-4) 0;
    }
}

.dateControls {
    display: flex;
    gap: var(--space-4);
    justify-content: center;
}

.calendar {
    > p {
        font-weight: var(--minor-font-weight);
    }

    --right-flex: flex-end;
}

.calendar > p {
    font-weight: var(--minor-font-weight);
}

.calendarGrid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: var(--space-4);

    padding-inline: var(--space-12);
    padding-block-start: 0;
}

.weekdayHeaders {
    text-align: center;
    color: var(--display-onlight-tertiary);
}

.day {
    display: flex;
    align-items: center;
    justify-content: center;
    block-size: 28px;
}

.today {
    color: var(--productui-meta-red);
    font-weight: var(--font-weight-medium);
}

.selected {
    margin-inline: 3px;
    background-color: var(--productui-meta-red);
    border-radius: var(--border-radius-full);

    p {
        color: #fff;
    }
}

.hidden {
    visibility: hidden;
}

.weekend {
    color: var(--display-onlight-tertiary);
}

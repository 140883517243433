.contextMenu {
    display: flex;
    flex-direction: column;
    gap: var(--space-8);
    min-inline-size: var(--context-menu-width, 0);

    position: absolute;
    top: var(--context-menu-top, unset);
    left: var(--context-menu-left, unset);
    bottom: var(--context-menu-bottom, unset);
    right: var(--context-menu-right, unset);

    padding: var(--context-menu-padding, var(--space-8));
    background-color: var(--elevated-background);
    border: 1px solid var(--elevated-border);
    border-radius: var(--border-radius-xl);
    z-index: var(--product-ui-z-index-popup);
}

.contextMenuHeading {
    color: var(--display-onlight-primary);
    padding-inline: var(--space-8);
    padding-block-start: 6px;
    padding-block-end: var(--space-4);
}

.header {
    margin-inline: var(--space-8);
    margin-block-start: 0.375rem;
    margin-block-end: var(--space-4);
    color: var(--display-onlight-secondary);
}

.contextMenuItem {
    display: flex;
    align-items: center;
    gap: var(--space-8);
    padding: var(--space-4);
    padding-inline: var(--space-8);
    min-block-size: var(--tube-height-sm);
}

.listItemContainer {
    display: flex;
    flex-direction: column;
    gap: var(--space-8);
    color: var(--display-onlight-primary);
}

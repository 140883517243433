.scrollSection {
    width: 100%;
}

.scrollContainer {
    display: var(--display, none);
    align-self: flex-start;
    align-content: center;

    @media (--screen-gt-md) {
        --display: "block";
    }
}

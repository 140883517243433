.tipCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    > hr:first-child {
        margin-block-start: var(--space-32);
        margin-block-end: var(--space-48);
    }

    > hr:last-child {
        margin-block-start: var(--space-48);
        margin-block-end: var(--space-24);
    }
}

.content {
    max-inline-size: 600px;
}

.colorSecondary {
    color: var(--display-onlight-secondary);
}

.heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    > p {
        max-inline-size: 40.625rem; /* 650px */
        color: var(--display-onlight-secondary);
    }
}

@media (--screen-lt-md) {
    .heading {
        align-items: flex-start;
        text-align: left;
    }
}

.mobileStepper {
    display: flex;
    align-items: center;
    gap: var(--space-24);

    .progressContainer {
        block-size: var(--space-8);
        inline-size: 100%;
        border-radius: var(--border-radius-sm);
        overflow: hidden;
        position: relative;
    }

    .backgroundLine {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: var(--border-radius-sm);
        background-color: var(--ui-background);
    }

    .progressLine {
        position: absolute;
        top: 0;
        left: 0;
        inline-size: 50%;
        bottom: 0;
        border-radius: var(--border-radius-sm);
        background-color: var(--accents-red);
    }
}

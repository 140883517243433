.imageContainer {
    img {
        inline-size: 100%;
        block-size: auto;
    }

    margin-block-start: var(--space-64);

    @media (--screen-lt-md) {
        margin-block-start: var(--space-32);
    }
}

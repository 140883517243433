.blogRoll {
    --gap: var(--space-12);

    position: relative;
    display: grid;
    gap: var(--gap);
    grid-template-columns: repeat(auto-fit, minmax(min(18rem, 100%), 1fr));

    > * {
        block-size: 100%;
    }
}

.large {
    --gap: var(--space-32);
}

.backgroundIllustration {
    position: absolute;
    top: 50%;
    left: 50%;
    inline-size: 100%;
    height: auto;
    transform: translateX(-50%) translateY(-50%) scale(1.15);

    @media (--screen-lt-md) {
        display: none;
    }
}

.container {
    display: grid;
    grid-template-columns: minmax(0, 1fr);

    & > * {
        grid-column: 1;
        grid-row: 1;
    }

    &:hover {
        --overlay-visible: visible;
    }

    &.completed {
        --overlay-visible: visible;
    }
}

.controls {
    place-self: center;
    z-index: var(--product-ui-z-index-controls);
    visibility: var(--overlay-visible, hidden);
}

.awardContainer {
    --leaf-height: 60px;
    --leaf-width: 29px;

    display: flex;
    inline-size: max-content;

    p {
        color: var(--display-onlight-tertiary);
    }

    .right {
        transform: scale(1, 1) scale(-1, 1);
    }

    .left,
    .right {
        block-size: var(--leaf-height);
        inline-size: var(--leaf-width);
    }

    .content {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        gap: var(--space-8);
        width: 95px;
    }
}

@media (--screen-lt-sm) {
    .awardContainer {
        --leaf-height: 51px;
        --leaf-width: 25px;
    }
}

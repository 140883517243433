.container {
    border-bottom-right-radius: var(--border-radius-xl);
    border: 1px solid var(--elevated-border);
    background: var(--elevated-background);
    padding: var(--space-24) var(--space-16);
}

.controls {
    margin-block-start: var(--space-24);
    margin-block-end: var(--space-32);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .fieldSkeleton {
        inline-size: 200px;
    }
}

.members {
    > span {
        display: flex;
        margin-block-end: var(--space-16);
    }

    hr {
        margin-block-start: var(--space-4);
        margin-block-end: var(--space-4);
    }
}

.gridGallery {
    --image-size: 128px;
    --container-size: 152px;

    grid-area: 1;
    display: flex;
    flex-direction: column;
    gap: var(--space-16);
    transform: translateY(4%) rotate(-3deg);
    transform-origin: center;
}

.gridGalleryRow {
    --gap-size: var(--space-16);
    display: flex;
    gap: var(--gap-size);
    transform: translateX(calc(var(--container-size) * -0.8));
}

.gridGalleryRow:nth-child(odd) {
    transform: translateX(calc((var(--container-size) + var(--gap-size)) * -1.25));
}

.gridGalleryItem {
    box-sizing: content-box;
    flex: 0 0 var(--image-size);
    aspect-ratio: 1;
    padding: var(--space-12);
    overflow: hidden;
    background-color: var(--neutral);
    border-radius: var(--border-radius-full);
    border: 1px solid var(--greytransparent-300);
    box-shadow: var(--productui-shadow-elevated);
    transform: rotate(3deg);

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.wrapper {
    display: grid;
    position: relative; /* Ensures children can use absolute positioning */
    grid-template-columns: 1fr; /* Single column grid */
    grid-template-rows: 1fr; /* Single row grid */
    width: max-content; /* Adjusts to content size */
    height: max-content; /* Adjusts to content size */

    transform-style: preserve-3d;
    perspective: 1000px;

    cursor: pointer;

    @media (--screen-lt-md) {
        &:nth-child(even) {
            padding-inline-start: var(--space-24);
        }

        &:nth-child(odd) {
            padding-inline-end: var(--space-24);
        }
    }
}

.card {
    > div {
        block-size: 100%;
    }

    @media (--scren-lt-lg) {
        margin-left: 24px;
    }
}

.cardFront,
.cardBack {
    grid-column: 1 / -1; /* Both elements span the entire column */
    grid-row: 1 / -1;
    will-change: transform;
    background-color: var(--page-background, var(--composition-background-neutral, #fdf4eb));
    border-radius: var(--border-radius-lg);
}

.cardBack {
    opacity: 0;
    transform: rotateY(180deg);
    pointer-events: none;
}

.task {
    padding: var(--space-16);
    block-size: 100%;
    --background-hex: #fff;
    --card-background: color-mix(in srgb, var(--background-hex), transparent 95%);

    &.p1 {
        --background-hex: var(--red-500);
    }

    &.p2 {
        --background-hex: var(--orange-500);
    }

    &.p3 {
        --background-hex: var(--blue-500);
    }
}

.circle.borderWidth {
    /* Combine two classes for higher specificity */
    --circle-border-width: 1.5px;
    margin-block-start: var(--space-4);
}

.taskCircle {
    cursor: pointer;
    margin-block-start: var(--space-4);
}

.colorSecondary {
    color: var(--display-onlight-secondary);
}

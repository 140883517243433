.container {
    display: flex;
    flex-direction: var(--column-direction, row);
    align-items: center;
    gap: var(--gap, var(--space-96));

    inline-size: 100%;

    @media (--screen-lt-md) {
        --column-direction: column;
        justify-content: center;
    }

    @media (--screen-lt-xlg) {
        --gap: var(--space-48);
    }

    @media (--screen-lt-md) {
        --gap: var(--space-96);
    }
}

.featureBox {
    display: flex;
    flex-direction: column;
    gap: var(--space-24);

    inline-size: 100%;
    max-inline-size: 300px;
}

.text {
    display: flex;
    flex-direction: column;
    gap: var(--space-8);
}

.subtitle {
    color: var(--display-onlight-secondary);
}

.iconCard {
    --padding: var(--space-32) var(--space-16);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--space-12);
    transition: var(--card-active-transition);

    &:hover {
        text-decoration: none;
        border: 1px solid var(--card-border-onhover);
        box-shadow: var(--shadow-card-hover);
        background-color: var(--card-background-onhover);
    }

    padding: var(--padding);
    background-color: var(--card-background-idle);
    border: 1px solid var(--card-border-idle);
    box-shadow: var(--shadow-card-idle);
    border-radius: var(--card-border-radius);

    img {
        max-inline-size: 120px;
        inline-size: auto;
        block-size: 125px;
        object-fit: contain;
    }

    p {
        height: calc(2em * var(--line-height));
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media (--screen-gt-lg) {
        --padding: var(--space-32) var(--space-24);
    }
}

.confirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-block-size: calc(80vh - (var(--space-64) + var(--space-96)));
    max-inline-size: 450px;
    inline-size: 100%;
    gap: var(--space-48);

    .imageContainer {
        inline-size: 150px;
        block-size: 103px;

        img {
            max-inline-size: 100%;
        }
    }
}

.link {
    color: var(--display-onlight-color-blue);
    text-decoration: none;
    b {
        color: var(--display-onlight-color-blue);
    }
}

.snippetContainer {
    border-radius: var(--border-radius-lg);
    border: 1px solid var(--box-outline-border);
    background: var(--box-outline-background);
    padding: var(--space-16);
    margin-block-start: var(--margin-top, var(--space-16));

    @media (--screen-lt-md) {
        --margin-top: 0;
    }
}

.buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-12);
}

.productUICursor {
    position: absolute;
    inline-size: var(--size, 44px);
    block-size: var(--size, 44px);
    background-color: var(--ui-background);
    backdrop-filter: blur(0.5px);
    border: 2px solid var(--ui-border);
    border-radius: var(--border-radius-full);
    box-shadow: 0 1px 5px 0 rgba(42, 29, 48, 0.11);
    z-index: var(--product-ui-z-index-mouse);
}

.lg {
    --size: 52px;
}

.productUICursorContainer {
    position: relative;

    & > .productUICursor {
        inset-inline-start: 50%;
        inset-block-start: 50%;
        translate: -50% -50%;
    }
}

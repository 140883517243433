.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.imageWrapper {
    inline-size: 100%;
    max-inline-size: 547px;

    img {
        inline-size: 100%;
        block-size: auto;
    }
}

.reviewsContainer {
    display: flex;
    flex-direction: column;
    max-inline-size: 800px;
    text-align: center;
    margin-inline: auto;
    position: relative;

    .note {
        color: var(--display-onlight-secondary);
    }

    .awardsContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-end;
        gap: var(--gap, var(--space-48));

        @media (--screen-lt-md) {
            --gap: var(--space-32);
        }

        @media (--screen-lt-sm) {
            --gap: var(--space-24);
        }
    }
}

.awardMiddle {
    @media (--screen-gt-md) {
        padding-block-end: var(--space-24);
    }
}

.noteContainer {
    position: absolute;
    bottom: calc(-1 * var(--space-64));
    inline-size: 100%;
    padding-inline: var(--space-12);
}

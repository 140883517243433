.galleryContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--desktop-gap, var(--space-24));
}

.imageContainer {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr);
    margin: auto;
    aspect-ratio: 2 / 1;
    inline-size: 100%;
    border-radius: var(--border-radius-2xl);
    border: 0.5px solid var(--ui-border);
    overflow-y: hidden;

    > * {
        grid-column: 1;
        grid-row: 1;
    }
}

.mobileGalleryContainer {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr);
    inline-size: 100%;
}

.background {
    aspect-ratio: 2 / 1;
}

.image {
    inline-size: 100%;
    block-size: auto;

    transition: opacity var(--animation-duration-600) var(--animation-ease-base);
    z-index: 2;

    img {
        aspect-ratio: 2 / 1;
    }

    &.active {
        opacity: 1;
    }

    &:not(.active) {
        opacity: 0;
    }
}

.controlContainer {
    text-align: center;
}

.alertSection {
    text-align: center;
}

.title {
    display: flex;
    justify-content: center;
}

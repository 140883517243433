.container {
    --gap: var(--space-16);

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: var(--gap);
    mask-image: linear-gradient(
        to right,
        transparent 0%,
        rgb(0, 0, 0) 10%,
        rgb(0, 0, 0) 90%,
        transparent 100%
    );
    inline-size: 100%;
    overflow: hidden;
}

.marquee {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: space-around;
    gap: var(--gap);
    animation: marquee var(--duration) linear infinite;

    @keyframes marquee {
        0% {
            transform: translateX(0%);
        }

        100% {
            transform: translateX(calc(-100% - var(--gap)));
        }
    }
}

.enter {
    z-index: 2;
    transform: translateY(calc(var(--space-16) * -1)) scale(0.95);
    transform-origin: top;
    box-shadow: none;
}

.enterActive {
    transform: translateY(0) scale(1);
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.12);
    transition: var(--animation-duration-600) var(--animation-ease-base) 50ms;
    transition-property: transform opacity box-shadow;
}

.exit {
    z-index: 3;
    opacity: 1;
}

.exitActive {
    opacity: 0;
    transform: translateY(var(--space-32)) scale(1);
    transition: var(--animation-duration-350) ease-out;
    transition-property: transform opacity;
    pointer-events: none;
}

.containerTwoColumns {
    --stacked: [full-start col1-start] minmax(0, 1fr) [col1-end col2-start] minmax(0, 1fr)
        [col2-end full-end];
    --sideBySide: [full-start col1-start col2-start] minmax(0, 1fr) [full-end col1-end col2-end];

    display: grid;
    grid-template-columns: var(--template, var(--sideBySide));
    gap: var(--space-48);

    &.breakAbove_md {
        @media (--screen-gt-md) {
            --template: var(--stacked);
        }
    }

    &.breakAbove_lg {
        @media (--screen-gt-lg) {
            --template: var(--stacked);
        }
    }

    &.breakAbove_xlg {
        @media (--screen-gt-xlg) {
            --template: var(--stacked);
        }
    }
}

.textContainerInner {
    max-inline-size: var(--textContainerMaxInlineSize, initial);
    margin-inline: auto;

    @media (--screen-gt-lg) {
        &.lg {
            --textContainerMaxInlineSize: 31.25rem; /* 500px */
        }

        &.base {
            --textContainerMaxInlineSize: 25rem; /* 400px */
        }
    }
}

.comment {
    display: flex;
    align-items: flex-start;
    gap: var(--space-16);

    inline-size: 100%;

    .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--space-12);

        inline-size: 100%;
    }

    .copy {
        display: flex;
        flex-direction: column;
        gap: var(--space-8);

        inline-size: 100%;
    }

    .chips {
        display: flex;
        align-items: center;
        gap: var(--space-4);
    }

    .name {
        display: flex;
        align-items: center;
        gap: var(--space-8);
    }
}

.text {
    font-weight: var(--comment-font-weight, 400);
    text-decoration: var(--comment-text-decoration, none);
    color: var(--comment-color, var(--display-onlight-color-primary));
    line-height: 1.75;
}

.strong {
    --comment-font-weight: 600;
}

.link {
    --comment-text-decoration: underline;
}

.tertiary {
    --comment-color: var(--display-onlight-tertiary);
}

.reactionChip {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: var(--space-8);

    padding: 5px 10px;
    min-block-size: var(--tube-height-xs);

    border-radius: var(--border-radius-full);
    background-color: var(--background-color, var(--actionable-secondary-background-idle));

    &.selected {
        border: 1px solid var(--productui-meta-blue);
        color: var(--display-onlight-color-blue);
    }

    &.onhover {
        --background-color: var(--actionable-secondary-background-onhover);
    }

    & > * {
        line-height: 1;
    }
}

.reactionAdd {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    padding: 5px;

    border-radius: var(--border-radius-full);
    background-color: var(--background-color, var(--actionable-secondary-background-idle));

    &.onhover {
        --background-color: var(--actionable-secondary-background-onhover);
    }
}

.wrapper {
    inline-size: 100%;
    background: linear-gradient(
        181.57deg,
        rgba(243, 245, 240, 0) 1.41%,
        var(--footer-background) 43.97%
    );
    padding: var(--space-192) 0 var(--space-128);

    @media (--screen-lt-md) {
        padding: var(--space-96) 0 var(--space-96);
    }

    em {
        font-family: var(--font-family-body);
        font-style: italic;
    }
}

.section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--space-192);

    @media (--screen-lt-xlg) {
        gap: var(--space-64);
    }

    @media (--screen-lt-md) {
        gap: var(--space-48);
        flex-direction: column;
        text-align: center;
    }
}

.illustration {
    @media (--screen-lt-lg) {
        max-inline-size: 250px;
        height: auto;
    }
}

.cta {
    max-inline-size: 450px;

    @media (--screen-gt-md) {
        min-inline-size: 400px;
    }
}

.text {
    color: var(--display-onlight-secondary);
}

.logoWrapper {
    max-inline-size: 94.5rem;
    margin: 0 auto;
    position: relative;
    margin-block-start: var(--space-16);

    @media (--screen-lt-lg) {
        margin-block-start: var(--space-32);
    }

    &:first-child {
        margin-block-start: calc(
            -0.5 *
            clamp(var(--space-128), calc(5rem + 10vw), var(--space-192))
        );
    }
}

.illustration {
    margin-inline: auto;
    inline-size: calc(var(--vw, 1vw) * 100);
    max-inline-size: 94.5rem;
    overflow: hidden;

    img {
        inline-size: 100%;
        min-inline-size: 50rem;
        block-size: auto;
        margin-inline-start: 50%;
        transform: translateX(-50%);
    }
}

.logos {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    inline-size: 100%;
}

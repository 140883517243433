.td {
    border-radius: var(--radius-md);
    border: 1px solid var(--color-black-04);
    padding: var(--space-16);

    p {
        font-size: var(--font-size-16);
        line-height: var(--line-height-24);
    }

    p:not(:first-child) {
        margin-top: var(--space-16);
    }

    a {
        position: relative;
        color: var(--blue-500);
        transition: color var(--animation-duration-350) var(--animation-ease-base);
    }

    ul {
        padding-top: 0;
        padding-bottom: var(--space-8);
    }

    li {
        margin-inline-start: -20px;
    }

    blockquote {
        margin-top: var(--space-16);
        padding: var(--space-8);
        background-color: #fff9f3;
        color: var(--color-black-07);
    }
}

.form {
    --marketist-link-color: var(--display-onlight-color-blue);

    max-inline-size: 28rem;
    margin: var(--space-32) auto 0;
    position: relative;

    input {
        --padding-inline-end: 25%;
    }

    button {
        position: absolute;
        top: var(--space-8);
        right: var(--space-8);
    }
}

.hug {
    max-inline-size: 40.625rem;
    margin-inline: auto;
    text-align: center;
    position: relative;
}

.colorSecondary {
    color: var(--display-onlight-secondary);
}

.successState {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

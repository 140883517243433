.alert {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    column-gap: var(--space-8);
    min-block-size: var(--tube-height-base);
    padding: var(--space-8) var(--space-16);

    background-color: var(--box-default-background);
    border-radius: var(--border-radius-lg);
    text-align: center;
}

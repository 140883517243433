.teamsLayout {
    display: flex;
    flex-direction: column;
    gap: clamp(var(--space-128), calc(5rem + 10vw), var(--space-192));
}

.allDevices {
    @media (--screen-lt-md) {
        text-align: center;
    }
}

.devicesSpotIllustration {
    margin-inline: auto;
    inline-size: calc(var(--vw, 1vw) * 100);
    max-inline-size: 820px;
    overflow: hidden;

    img {
        inline-size: 100%;
        min-inline-size: 450px;
        block-size: auto;
        margin-inline-start: 50%;
        transform: translateX(-50%);
    }
}

.workspaceSection {
    .radioGroupWrapper {
        display: flex;
        flex-direction: column;
        gap: var(--space-24);

        a {
            align-self: center;
        }
    }
}

.templatesContainer {
    display: flex;
    align-items: center;
    flex-direction: column;

    .grid {
        display: flex;
        flex-direction: var(--grid-direction, row);
        gap: var(--gap, var(--space-32));

        @media (--screen-lt-md) {
            --grid-direction: column;
            --gap: var(--space-24);
        }
    }
}

.exploreContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    .grid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(min(144px, 100%), 1fr));
        grid-gap: var(--space-12);
    }
}

.prefooter {
    display: flex;
    flex-direction: column;
    align-items: center;

    .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--space-12);
    }
}

.prefooterBackground {
    background: linear-gradient(0deg, var(--composition-background-red) 58%, transparent 100%);
    width: 100%;
}

.faq {
    margin-block-end: calc(-1 * var(--space-64));
}

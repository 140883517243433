.wrapper {
    inline-size: 100%;
    max-inline-size: 94.5rem;
    margin: 0 auto;
    position: relative;
    margin-block-start: var(--space-16);

    @media (--screen-lt-lg) {
        margin-block-start: var(--space-32);
    }

    &:first-child {
        margin-block-start: calc(
            -0.5 *
            clamp(var(--space-128), calc(5rem + 10vw), var(--space-192))
        );
    }
}

.illustration {
    margin-inline: auto;
    inline-size: 100%;
    overflow: hidden;

    img {
        inline-size: 94.5rem;
        block-size: auto;
        margin-inline-start: 50%;
        transform: translateX(-50%);
    }
}

.content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    inline-size: 100%;
}

.note {
    position: absolute;
    max-inline-size: 330px;
    left: 50%;
    transform: translateY(-70%);

    @media (--screen-lt-xlg) {
        transform: translateY(-40%);
    }

    @media (--screen-lt-md) {
        transform: translateX(-50%) translateY(-25%);
    }
}

.container {
    --height: var(--tube-height-sm);
    --border-radius: var(--border-radius-sm);
    --gap: var(--space-8);
    --padding: var(--space-4) var(--space-8);

    padding: var(--padding);
    min-block-size: var(--height);
    inline-size: 100%;
    border-radius: var(--border-radius);
    position: relative;

    display: flex;
    align-items: center;
    gap: var(--gap);

    .right,
    .left {
        display: flex;
        align-items: var(--left-right-align-items, center);
        gap: var(--left-right-gap, var(--space-8));
    }

    .right {
        flex: var(--right-flex, inherit);
    }

    .left {
        color: var(--display-onlight-primary);
        flex: var(--left-flex, auto);

        svg {
            flex-shrink: 0;
        }
    }

    .subLabel {
        color: var(--display-onlight-secondary);
    }

    .icon {
        transition: transform var(--animation-duration-350) var(--animation-ease-base);
    }

    .open {
        transform: rotate(-0.25turn);
    }

    .stack {
        display: flex;
        flex-direction: column;
        flex: auto;
        gap: var(--space-4);
    }

    .abstract {
        inline-size: 100%;
    }

    .row {
        flex-direction: row;
    }

    &:has(.projectStatus) {
        --right-flex: 1;
        --left-flex: 1;
    }
}
.basicType {
    --height: var(--tube-height-sm);
    --border-radius: var(--border-radius-sm);
    --gap: var(--space-8);
    --padding: var(--space-4) var(--space-8);
}

.sideBarType {
    --height: var(--tube-height-sm);
    --border-radius: var(--border-radius-sm);
    --gap: var(--space-8);
    --padding: var(--space-4) var(--space-8);
    --list-item-text-active: var(--display-onlight-primary);

    .left {
        color: var(--display-onlight-secondary);
    }
}

.projectType {
    --height: var(--tube-height-base);
    --border-radius: var(--border-radius-xl);
    --padding: var(--space-12) 15px;
    --gap: var(--space-12);

    .left > div {
        flex: 1;
    }
}

.permissionType {
    --height: var(--tube-height-base);
    --border-radius: var(--border-radius-xl);
    --gap: var(--space-12);
    --padding: 9.5px var(--space-12);

    .left {
        --left-right-gap: var(--space-12);
        --left-flex: 0;
    }

    .middle {
        flex: 1;
        display: flex;
        gap: var(--space-4);
        flex-direction: column;
    }
}

.activityType {
    --height: var(--tube-height-base);
    --border-radius: var(--border-radius-xl);
    --gap: var(--space-12);
    --padding: 6px var(--space-12);
}

.roleType {
    --height: var(--tube-height-base);
    --border-radius: var(--border-radius-sm);
    --gap: var(--space-16);
    --padding: var(--space-8);

    .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        flex: 1;
        gap: var(--space-4);
    }

    .middle {
        flex: 1;
    }

    .right {
        inline-size: 24px;
    }

    .icon {
        flex: 0;
    }

    .icon svg {
        inline-size: 24px;
        block-size: 24px;
        color: var(--display-onlight-tertiary);
    }

    .subtitle {
        color: var(--display-onlight-secondary);
    }

    .stack {
        gap: 0;
    }
}

.hovered {
    background-color: var(
        --list-item-background-hover,
        var(--actionable-quaternary-background-onhover)
    );

    .left {
        color: var(--display-onlight-primary);
    }
}

.active {
    background-color: var(
        --list-item-background-active,
        var(--productui-sidebar-actionable-quaternary-background-active)
    );

    .left {
        color: var(--list-item-text-active, var(--productui-primary-theme-color));
    }
}

.listItemWrapper {
    position: relative;
}

.sidebarListItem {
    --list-item-background-active: var(--productui-sidebar-actionable-quaternary-background-active);

    &:has(.contextMenuListItemRight) {
        --left-flex: 0 1 auto;
    }

    &.active:not(.sideBarType) .left {
        color: var(--productui-display-color);
    }
}

.label {
    display: flex;
    align-items: center;
    gap: var(--space-2);

    .locked {
        flex: 0;
        inline-size: var(--icon-base);
        block-size: var(--icon-base);
        color: var(--display-onlight-tertiary);
    }
}

.joinLabel {
    margin-block-start: var(--space-2);
    color: var(--display-onlight-color-green);
}

.subtitle {
    margin-block-start: var(--space-2);
    color: var(--display-onlight-secondary);
}

.folderListItem {
    display: flex;
    align-items: baseline;
    margin-inline-start: calc(var(--space-24) * -1);
}

.folderListItemContent {
    inline-size: 100%;
}

.folderListItemHeader {
    display: flex;
    align-items: center;

    & > .container {
        --padding: 6px var(--space-12);
    }
}

.folderListItemChildren {
    margin-inline-start: var(--space-32);

    & > .container {
        --padding: 6px var(--space-12);
    }
}

.contextMenuListItemRight {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-4);
    color: var(--display-onlight-secondary);

    svg {
        color: var(--display-onlight-tertiary);
    }
}

.projectStatus {
    display: flex;
    align-items: center;
    gap: var(--space-12);

    .statuses {
        display: flex;
        align-items: center;
        gap: var(--space-8);
    }
}

.taskStatus {
    display: flex;
    align-items: center;
    gap: var(--space-4);

    p {
        color: var(--task-status-color);
    }
}

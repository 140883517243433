.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    inline-size: 100%;

    .title {
        --section-mb: 40px;
        margin-inline: var(--space-24);

        @media (--screen-lt-md) {
            --section-mb: 20px;
        }
    }
}

@media (--screen-gt-md) {
    .iframeContainer {
        border-radius: var(--border-radius-lg);
        border: 1px solid var(--ui-border);

        background-image: var(--bg-url);
        background-size: cover;
        background-position: center;
        overflow-y: hidden;
    }

    .iframe {
        padding: var(--iframe-padding, var(--space-32));
        padding-block-end: 0;
        /* To hide the embed's bottom border, move the element down and hide overflow */
        margin-block-end: -8px;
    }
}

@media (--screen-lt-lg) {
    .iframe {
        --iframe-padding: var(--space-16);
        padding-block-end: 0;
    }
}

@media (--screen-lt-md) {
    .iframe {
        --iframe-padding: 0;
    }
}

.taskContainer {
    display: flex;
    align-items: center;
    gap: var(--space-12);
    block-size: var(--tube-height-sm);
}

.item {
    display: flex;
    gap: var(--space-12);
    align-items: center;
    block-size: var(--tube-height-sm);
}

.badge {
    border-radius: var(--border-radius-sm);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--space-4) var(--space-8);
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-64);
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.copy {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-8);
    text-align: center;

    .subheader {
        max-inline-size: 650px;
        color: var(--display-onlight-secondary);

        a {
            text-decoration: none;
        }

        a:hover {
            text-decoration: underline;
        }
    }
}

.main {
    display: flex;
    flex-direction: column;
    gap: var(--space-48);
    flex-grow: 1;
    max-inline-size: 650px;

    @media (--screen-lt-lg) {
        margin-inline: auto;
    }
}

.features {
    li {
        display: flex;
        gap: var(--space-12);
        align-items: flex-start;
        padding: var(--space-16) 0;

        .iconWrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            margin-block-start: 5px;

            svg {
                width: 24px;
                height: 24px;
            }
        }

        &:not(:last-child) {
            border-bottom: 1px solid var(--ui-border);
        }
    }
}

.carousel {
    inline-size: 100%;

    video {
        inline-size: 100%;
        block-size: auto;
    }
}

.screenshot {
    object-fit: cover;
}

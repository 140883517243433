.framebackground {
    background-color: var(--light-color, var(--productui-background-light-red));
}

.lightColorFill {
    fill: var(--light-color, var(--productui-background-light-red));
}

.darkColorFill {
    fill: var(--dark-color, var(--productui-background-dark-red));
}

.image {
    inline-size: 100%;
    block-size: 100%;
    object-fit: cover;
}

.tableContainer {
    margin: var(--space-32) 0 var(--space-48);
    overflow: scroll;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        display: none;
    }
}

.articleTable {
    width: 100%;
    table-layout: auto;
    text-align: left;
    border-collapse: collapse;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0;
    font-weight: 400;

    &:-webkit-scrollbar {
        display: none;
    }

    caption {
        display: none;
    }

    * {
        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        font-weight: inherit;
    }

    thead {
        border-bottom: 1px solid var(--color-grey-03);

        th {
            vertical-align: bottom;
            font-weight: 600;
            padding: var(--space-16);
            padding-right: 0;
            font-size: 0.75rem;
            line-height: 1.2;
            letter-spacing: 0.02em;
            font-weight: 500;
            text-transform: uppercase;
            color: var(--color-theme-primary, var(--color-td-primary));
            min-width: 120px;

            > * {
                font-size: inherit;
                line-height: inherit;
                letter-spacing: inherit;
                font-weight: inherit;
            }

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }

    tbody {
        td {
            vertical-align: top;
            padding: var(--space-16);
            padding-right: 0;
            border-left: 0;
            border-right: 0;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }

        tr {
            border-bottom: 1px solid var(--color-grey-03);

            &:last-child {
                border-bottom-width: 0;
            }
        }
    }

    &[data-first-bold] tbody tr td:first-child {
        font-weight: bold;
    }
}

@media (--screen-lt-sm) {
    .articleTable {
        thead th:not(:last-child),
        tbody td:not(:last-child) {
            border-right: 1px solid var(--color-grey-03);
        }
        margin-left: 0;
    }
}

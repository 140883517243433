.choiceForm {
    composes: fadeIn from "styles/animations.module.css";
    animation-delay: 0.5s;
    margin: 0 auto;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(157px, 100%), 191px));
    gap: var(--space-12);
    justify-content: center;
}

.layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: clamp(var(--space-128), calc(5rem + 10vw), var(--space-192));
}

.withoutPrefooter {
    padding-block-end: var(--space-128);
}

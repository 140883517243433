.expandedContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--space-12);
    inline-size: 100%;

    padding: 15px var(--space-16);
    border-radius: var(--border-radius-lg);
    border: 1px solid var(--field-outline-border-onhover);
    background: var(--field-outline-background-onhover);
}

.container {
    display: flex;
    align-items: center;
    gap: var(--space-12);
    inline-size: 100%;

    .commentField {
        block-size: var(--tube-height-sm);
        padding: 0 var(--space-16);
        border-radius: var(--border-radius-full);
        border: 1px solid var(--field-outline-border-idle);
        background: var(--field-outline-background-idle);
    }
}

.hovered {
    .commentField {
        background-color: var(--ui-background);

        * {
            color: var(--display-onlight-primary);
        }
    }
}

.commentField {
    display: flex;
    inline-size: 100%;
    align-items: center;
}

.chips {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-8);
    align-items: center;
}

.chip {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: var(--space-8);

    padding-inline-end: 7px;
    border-radius: var(--border-radius-full);
    background-color: var(--ui-background);

    .avatar {
        border-radius: var(--border-radius-full);
    }
}

.footerSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    inline-size: 100%;

    .buttons {
        display: flex;
        gap: var(--space-8);
    }
}

.categoryList {
    display: flex;
    gap: var(--space-12);
    justify-content: center;
}

.article {
    @media (--screen-lt-md) {
        overflow: hidden;
    }
}

.container.noToc {
    @media (--screen-gt-lg) {
        grid-template-columns: auto minmax(0, 50rem) auto;
        gap: 0;
    }
}

.colorSecondary {
    color: var(--display-onlight-secondary);
}

.colorTertiary {
    color: var(--display-onlight-tertiary);
}

.textCenter {
    text-align: center;
}

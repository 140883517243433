.mobileList {
    padding-inline: var(--space-24);
    padding-block-end: var(--space-24);
    display: flex;
    flex-direction: column;
    gap: var(--space-12);

    .templateIconCard {
        position: relative;
        display: grid;
        grid-template-columns: 100px 1fr;
        align-items: center;

        .imageContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            border-top-left-radius: var(--card-border-radius);
            border-bottom-left-radius: var(--card-border-radius);
            border-right: 1px solid var(--box-outline-border);
            padding: var(--space-8);
        }

        > a {
            &:hover {
                text-decoration: none;
            }

            &::after {
                position: absolute;
                content: "";
                inset: 0;
            }
        }
    }
}

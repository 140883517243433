.grid {
    --gap: var(--space-24);

    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    gap: var(--gap, var(--space-24));
    inline-size: fit-content;
    margin-inline: auto;
}

.publicationQuote {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    max-inline-size: 319px;
    block-size: 136px;
    padding: 0 var(--space-24);
    color: var(--display-onlight-primary);
    text-align: center;

    &::after {
        content: "";
        position: absolute;
        background-color: var(--ui-border);
        inline-size: 1px;
        display: block;
        block-size: 100%;
        inset: 0 0 0 calc(var(--gap) * -0.5);
    }

    &:first-child::after {
        opacity: 0;
    }

    @media (--screen-lt-lg) {
        &:first-child::after {
            opacity: 1;
        }
    }
}

.blockquote {
    display: flex;
    flex: 0 0 72px;
    align-items: center;
    justify-content: center;
    text-wrap: balance;
}

.logoContainer {
    max-block-size: 41px;

    img {
        block-size: 100%;
        inline-size: 100%;
        object-fit: contain;
    }
}

.sectionHeader {
    display: flex;
    flex-direction: column;
    gap: var(--gap, var(--space-8));
    margin-block-end: var(--section-mb, var(--space-48));
    text-align: var(--text-align, center);

    @media (--screen-lt-md) {
        text-align: var(--text-align-narrow, center);
    }

    @media (--screen-gt-md) {
        --section-mb: var(--space-64);
        max-inline-size: 673px;
    }
}

.title {
    color: var(--display-onlight-primary);
}

.subtitle {
    max-inline-size: 40.625rem;
    color: var(--display-onlight-secondary);
}

.cta {
    margin-block-start: var(--space-16);
}

.lg {
    --gap: var(--space-24);

    @media (--screen-lt-md) {
        --gap: var(--space-16);
    }
}

.reviewsCounter {
    display: flex;
    align-items: center;
    margin-block: var(--space-12);
    color: var(--display-onlight-secondary);

    .platformIcons {
        display: flex;
        align-items: center;
        block-size: 20px;

        > svg:last-child {
            margin-inline-start: var(--space-4);
        }
    }
}

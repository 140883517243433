@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 2;
    }
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateY(-12px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.slideIn {
    opacity: 0;
    transform: translateY(-12px);
    animation: slideIn var(--animation-duration-350) forwards var(--animation-ease-base);
}

.fadeIn {
    opacity: 0;
    animation: fadein var(--animation-duration-600) both var(--animation-ease-base);
}

.richTextHighlight {
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    margin: calc(var(--space-64) + var(--space-24)) 0 var(--space-64);

    @media (--screen-lt-sm) {
        margin: var(--space-48) 0;
    }

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-inline-size: 125%;
        max-inline-size: 125%;
        /* inline-size: 728px;
        min-inline-size: 728px;
        max-inline-size: 728px;
        block-size: 298px;
        min-block-size: 298px;
        max-block-size: 298px; */

        /* @media (--screen-lt-md) {
            min-inline-size: 125%;
            max-inline-size: 125%;
        } */
    }

    blockquote {
        position: relative;
        min-block-size: 200px;
        max-inline-size: 602px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family-serif);
        font-style: italic;
        font-size: 22px;
        line-height: 39.6px;

        @media (--screen-lt-md) {
            max-inline-size: 351px;
            font-size: 20px;
            line-height: 36px;
        }
    }
}

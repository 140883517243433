.field {
    display: flex;
    block-size: 36px;
    padding: 0 var(--space-8);
    align-items: center;
    gap: var(--space-8);

    border-radius: var(--border-radius-sm);
    border: 1px solid var(--field-outline-border-idle);
    background: var(--field-outline-background-idle);

    &.disabled {
        opacity: 0.4;
    }

    &.hovered {
        border-color: var(--field-outline-border-onhover);
    }
}

.placeholder {
    color: var(--display-onlight-tertiary);
}

.children {
    flex: 1;
}

.fields {
    display: flex;
    flex-direction: column;
    gap: var(--space-24);
}

.imageWrapper {
    display: flex;
    justify-content: center;
    padding-block-start: var(--space-32);

    img {
        inline-size: 100%;
        max-inline-size: 776px;
        height: auto;
    }
}

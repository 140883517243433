.container {
    .headerWrapper {
        display: flex;
        flex-direction: column;
        gap: var(--space-48);
        align-items: center;
    }

    .imageWrapper {
        max-inline-size: 296px;

        img {
            inline-size: 100%;
            block-size: auto;
        }
    }

    .grid {
        display: flex;
        gap: var(--space-64);

        @media (--screen-lt-md) {
            flex-direction: column;
            gap: var(--space-32);
        }
    }

    .item {
        display: flex;
        flex-direction: column;
        gap: var(--space-8);

        p:nth-child(2) {
            color: var(--display-onlight-secondary);
        }
    }
}

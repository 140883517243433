.imageContainer {
    margin-inline: auto;
    inline-size: calc(var(--vw, 1vw) * 100);
    max-inline-size: 1200px;
    overflow: hidden;
}

.image {
    inline-size: 100%;
    min-inline-size: 460px;
    block-size: auto;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-block-end: calc(-1 * var(--space-16));
}

.button {
    margin-block-start: var(--space-24);
}

.lineThrough {
    text-decoration: line-through;
    color: var(--display-onlight-tertiary);
    /**
     * Tertiary color is based on opacity.
     * With strikethrough, this results in different colors
     * of text/line and their intersections.
     * Below, we calculate the absolute value of the tertiary color
     * by mixing the primary with page background color at tertiary opacity level.
     */
    color: color-mix(
        in srgb,
        var(--display-onlight-primary) 50%,
        var(--composition-background-neutral) 50%
    );
}

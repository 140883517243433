.galleryContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--space-24);
}

.tileGroupContainer {
    max-inline-size: 100vw;
}

.sceneContainer {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr);
    margin: auto;

    > * {
        grid-column: 1;
        grid-row: 1;
    }
}

.scene {
    transition: opacity var(--animation-duration-600) var(--animation-ease-base);
    z-index: 2;

    &.active {
        opacity: 1;
    }

    &:not(.active) {
        opacity: 0;
    }
}

.illustrationContainer {
    margin-inline: auto;
    inline-size: calc(var(--vw, 1vw) * 100);
    max-inline-size: 680px;
    overflow: hidden;
}

.illustration {
    inline-size: 100%;
    min-inline-size: 450px;
    block-size: auto;
    margin-inline-start: 50%;
    transform: translateX(-50%);
}

.headerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tableOfContents {
    block-size: fit-content;
    max-block-size: calc(100vh - (var(--space-96) * 2));
    overflow: scroll;
    display: flex;
    flex-direction: column;
    gap: var(--space-2);

    &::-webkit-scrollbar {
        display: none;
    }

    @media (--screen-lt-md) {
        position: static;
        block-size: auto;
        top: unset;
    }
}

.hero {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: var(--space-32);

    --product-ui-inline-size: 31.25rem; /* 500px */
    --product-ui-block-size: 29.125rem; /* 466px */

    @media (--screen-gt-md) {
        --product-ui-inline-size: 82rem; /* 1312px */
        --product-ui-block-size: 46.125rem; /* 738px */
    }

    @media (--screen-gt-xlg) {
        --product-ui-inline-size: 39.5rem; /* 632px */
        --product-ui-block-size: 22.25rem; /* 356px */

        flex-direction: row;
        gap: var(--space-48);
    }

    @media (min-width: 1200px) {
        --product-ui-inline-size: 45.5rem; /* 712px */
        --product-ui-block-size: 22.25rem; /* 356px */
    }
}

.details {
    max-inline-size: 500px;
    margin-inline-start: 66px;

    > p {
        color: var(--display-onlight-secondary);
    }

    @media (--screen-lt-xlg) {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        max-inline-size: 650px;
        margin-inline: auto;
    }
}

.illustration {
    flex-shrink: 0;
    align-items: center;
    max-inline-size: 100%;
}

.imageContainer {
    max-inline-size: 100%;

    --img-inline-size: var(--product-ui-inline-size);
    --img-block-size: auto;

    position: relative;
    inline-size: var(--img-inline-size);

    overflow: hidden;
    background-color: var(--elevated-background);
    border: 0.5px solid var(--greytransparent-200);
    border-radius: var(--product-frame-border-radius, 15px);
    display: flex;
    align-items: flex-end;

    .backgroundImage {
        position: absolute;
        inset: 0;
        inline-size: var(--img-inline-size);
        block-size: var(--img-block-size);
    }

    .foregroundImage {
        max-inline-size: 100%;
        position: relative;
        inline-size: var(--img-inline-size);
        block-size: var(--img-block-size);
    }
}

.mobile {
    display: none;
}
@media (--screen-lt-md) {
    .desktop {
        display: none;
    }
    .mobile {
        display: block;
    }
}

.prefooterContainer {
    inline-size: 100%;
    display: flex;
    flex-direction: column;
    padding-block-end: var(--space-128);
    padding-block-start: var(--space-192);
    background: linear-gradient(180deg, rgba(243, 245, 240, 0) 1.41%, var(--red-100) 43.97%);

    .prefooter {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 0 var(--space-12) var(--space-64);
        text-align: center;

        h2 {
            margin-block-end: var(--space-8);
        }

        h3 {
            margin-block-end: var(--space-24);
            max-inline-size: 560px;
            color: var(--display-onlight-secondary);
        }
    }

    @media (--screen-lt-lg) {
        padding-block-end: var(--space-96);
    }
}

.accessoryView {
    color: var(--display-onlight-secondary);
    display: flex;
    align-items: center;
    gap: var(--space-4);
}

.icon {
    block-size: var(--space-24);
    inline-size: var(--space-24);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block-start: 1px;

    svg {
        color: var(--display-onlight-tertiary);
    }
}

.handwrittenBox {
    display: flex;

    p {
        padding: var(--space-8);
        text-align: center;
        transform: rotate(var(--text-tilt, 0));
        color: var(--display-onlight-secondary);
    }

    svg {
        inline-size: auto;
    }
}

.right {
    flex-direction: row;
    align-items: flex-end;
}

.left {
    flex-direction: row-reverse;
    align-items: flex-end;
}

.top {
    flex-direction: column-reverse;
    align-items: center;
}

.bottom {
    flex-direction: column;
    align-items: center;
}
